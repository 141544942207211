<div class="login-form">
  <h2 class="header">{{ welcomeText }}</h2>
  <div class="existing-account" *ngIf="subWelcomeText">
    {{ subWelcomeText }}
  </div>
  <div class="existing-account" *ngIf="displaySignup">
    <p>Don't have an account?</p>
    &nbsp;
    <a *ngIf="signupRoute; else signupButton" mat-button class="sign-up-link" color="primary"
      [routerLink]="signupRoute">Sign
      Up</a>

    <ng-template #signupButton>
      <a mat-button class="sign-up-link" color="primary" (click)="onSignupButtonClick()">Sign
        Up</a>
    </ng-template>
  </div>
  <form class="form-content fx-column" [formGroup]="formGroup">
    <!-- Email -->
    <mat-form-field [appearance]="appearance">
      <mat-label>Email Address</mat-label>
      <input matInput required maxlength="100" type="email" class="email" formControlName="email"
        email matTooltip="Please use format: name@example.com" matTooltipPosition="above"
        [matTooltipDisabled]="formGroup.get('email')?.valid" />
      <mat-error>
        Please enter a valid email address
      </mat-error>
    </mat-form-field>

    <!-- Password -->
    <mat-form-field [appearance]="appearance">
      <mat-label>Password</mat-label>
      <input matInput required minlength="8" maxlength="100" class="password" type="password"
        formControlName="password" type="password" (keyup.enter)="onLoginButtonClick()" />
      <mat-error>
        Please enter minimum of 8 characters
      </mat-error>
    </mat-form-field>

    <mat-checkbox *ngIf="displayRememberMe" class="remember-me-checkbox" formControlName="rememberMe">Remember Me
    </mat-checkbox>

    <div class="submit-button-container" matTooltip="Please complete all fields." matTooltipPosition="below"
      [matTooltipDisabled]="formGroup.valid">
      <button class="submit-button" type="submit" color="primary" (click)="onLoginButtonClick()"
        [disabled]="!formGroup.valid || loading" mat-raised-button>
        @if (loading) {
          <mat-spinner [diameter]="36" />
        }
        @else {
          Login
        }
      </button>
    </div>

    <div class="forgot-password-container">
      <a mat-button type="button" [routerLink]="forgotPasswordRoute">Forgot password?</a>
    </div>

    <p class="or-container">
      <span class="or-line"></span><span class="or-text">or</span><span class="or-line"></span>
    </p>
  </form>

  <div class="social-buttons">
    <gth-google-button (socialClick)="onGoogleLoginButtonClick()"></gth-google-button>
  </div>
</div>