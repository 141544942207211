import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { LoginType } from '@index/enums/login';
import { LoginContract } from '@index/interfaces/login';
import { APP_ROUTES } from '@shared/helpers';

@Component({
  selector: 'gth-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class GthLoginFormComponent implements AfterViewInit {
  /**
   * Route leveraged by Sign Up button
   */
  @Input()
  signupRoute?: string;

  @Input()
  forgotPasswordRoute?: string;

  /**
   * Primary Header Text
   */
  @Input()
  welcomeText = 'Let\'s sign you in';

  @Input()
  subWelcomeText = '';

  /**
   * Input Field Appearance
   */
  @Input()
  appearance: MatFormFieldAppearance = 'fill';

  /**
   * Indicates whether the login process is currently loading.
   */
  @Input()
  loading = false;

  /**
   * If set to true the sign up link is displayed
   */
  @Input()
  displaySignup = true;

  /**
   * If set to true, remember me checkbox is displayed
   */
  @Input()
  displayRememberMe = true;

  /**
   * Event Emitted when Login or Social buttons are clicked
   */
  @Output()
  login = new EventEmitter<LoginContract>();

  @Output()
  signup = new EventEmitter<void>();

  /**
   * Email set within the email form field
   */
  @Input()
  get email() {
    const ctrl = this.formGroup.get('email');
    return ctrl ? ctrl.value : '';
  }
  set email(val: string) {
    const ctrl = this.formGroup.get('email');
    if (!ctrl) {
      return;
    }
    ctrl.setValue(val);
  }

  formGroup = new UntypedFormGroup({
    email: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
    rememberMe: new UntypedFormControl(false),
  });

  constructor(private changeDectetor: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.changeDectetor.detectChanges();
  }
  public get APP_ROUTES() {
    return APP_ROUTES;
  }

  /**
   * Event Handler for Login Button
   * @return {void}
   */
  onLoginButtonClick() {
    if (!this.formGroup.valid) {
      return;
    }
    const form = this.formGroup.getRawValue();
    this.login.emit({
      email: form.email,
      password: form.password,
      rememberMe: form.rememberMe,
      loginType: LoginType.Email,
    });
  }

  onSignupButtonClick() {
    this.signup.emit();
  }

  /**
   * Event Handler for Google Social Button
   * @return {void}
   */
  onGoogleLoginButtonClick() {
    this.login.emit({
      email: '',
      password: '',
      rememberMe: false,
      loginType: LoginType.Google,
    });
  }

  /**
   * Event Handler for Facebook Social Button
   * @return {void}
   */
  onFacebookLoginButtonClick() {
    this.login.emit({
      email: '',
      password: '',
      rememberMe: false,
      loginType: LoginType.Facebook,
    });
  }
}
